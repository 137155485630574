'use strict';

import Loading from '../components/Loading';
import PassengerFile from '../components/PassengerFile';

const FormInput =  React.lazy(() => import( /* webpackChunkName: "FormInput" */ '../components/FormInput'));

class EditPassengerModal extends React.Component {
    constructor(props) {
        super(props);
        this.initState = {
            fid: null,
            ufid: null,
            ufpid: null,
            firstName: '',
            lastName: '',
            thirdName: '',
            birthDate: '',
            passportsExtended: [],
            deletePassports: [],
            errors: {},
            error_message: null,
            file_message: null,
            isFetching: false,
        }
        this.state = this.initState;

        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        listenEvent('react.flightDataPassenger', data => {
            if(data.passenger === null) {
                this.setState(this.initState);
            } else {
                this.setState({
                    ...this.initState,
                    fid: data.fid,
                    ufid: data.ufid,
                    ...data.passenger,
                });
            }
        })
    }

    handleChangePassport(index, file) {

        this.setState({file_message: null})

        const isPdf = file.data.type === "application/pdf"
        const newPassport = {file: file, thumbnail: isPdf ? '/images/icons/file-types/pdf.svg' : file };
        if (index in this.state.passportsExtended) {
            const passportsExtended = [...this.state.passportsExtended]
            const [old] = passportsExtended.splice(index, 1, newPassport)
            const deletePassports = [...this.state.deletePassports]
            if (old && old.id)
                deletePassports.push(old.id)
            this.setState({passportsExtended, deletePassports})
        } else {

            this.setState({
                passportsExtended: [
                    ...this.state.passportsExtended,
                    newPassport
                ]
            })
        }
    }

    handleAddPassportError(message) {
        this.setState({file_message: message})
    }

    handleClose(e) {
        if(e) e.preventDefault();
        this.setState(this.initState)
        $.fancybox.close();
    }

    handleSubmit(e) {
        e.preventDefault();
        const {fid, ufid, ufpid, firstName, lastName, thirdName, passportsExtended, deletePassports, birthDate} = this.state;
        this.setState({isFetching: true})
        flightDataAction.savePassenger({fid, ufid, ufpid, firstName, lastName, thirdName, birthDate, passports: passportsExtended, deletePassports}).then(response => {
            this.setState({isFetching: false, errors: {}, error_message: null, file_message: null})
            if(response.status === 'success') {
                raiseEvent(ufpid !== null ? 'react.updatePassenger' : 'react.addPassenger', {
                    passenger: response.passenger
                });
                this.handleClose()
            } else {
                this.setState({errors: response.errors, error_message: response.message || null})
            }
        });
    }

    renderPassports(passports, ufpid) {

        const string_count = 4
        const empty_passports_count = string_count - passports.length % string_count
        const empty_passports = [...Array(empty_passports_count).keys()].map(e => e + passports.length + 1)

        return <div className="passenger-form__img">
            {passports.map((passport, i) => <PassengerFile
                key={ufpid + i}
                label={`${tMsg('Паспорт')} ${i + 1}`}
                file={passport.thumbnail}
                onChange={file => this.handleChangePassport(i, file)}
                onError={error => this.handleAddPassportError(error)}
            />)}
            {empty_passports.map((number, i) => <PassengerFile
                key={ufpid + number}
                label={`${tMsg('Паспорт')} ${number}`}
                onChange={file => this.handleChangePassport(undefined, file)}
                onError={error => this.handleAddPassportError(error)}
            />)}
        </div>
    }

    render() {
        const {ufpid, firstName, lastName, thirdName, birthDate, passportsExtended, errors, error_message, file_message, isFetching} = this.state;

        return <div>
            <div className="passenger-modal__title">
                {ufpid === null ? tMsg('Добавление пассажира') : tMsg('Редактирование пассажира')}
            </div>
            <form action="">
                <div className="passenger-form">
                    <div className="passenger-row__info">
                        <div className="passenger-form__row">
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Фамилия')}
                                    value={lastName}
                                    onChange={lastName => this.setState({lastName})}
                                    error={errors.surname}
                                />
                            </React.Suspense>
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Имя')}
                                    value={firstName}
                                    onChange={firstName => this.setState({firstName})}
                                    error={errors.firstname || error_message}
                                />
                            </React.Suspense>
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Отчество')}
                                    value={thirdName}
                                    onChange={thirdName => this.setState({thirdName})}
                                    error={errors.patronymic}
                                />
                            </React.Suspense>
                        </div>
                        <div className="passenger-form__row2">
                            <React.Suspense>
                                <FormInput
                                    type={'date'}
                                    readOnly={true}
                                    className={'passenger-form__col'}
                                    label={tMsg('Дата рождения')}
                                    value={birthDate}
                                    onChange={birthDate => this.setState({birthDate})}
                                    error={errors.bday}
                                />
                            </React.Suspense>
                        </div>
                    </div>

                    {this.renderPassports(passportsExtended || [], ufpid)}

                    {error_message && <div style={{color:'red',textAlign:'center',marginBottom:40}}>
                        {error_message}
                    </div>}

                    {file_message && <div style={{color:'red',textAlign:'center',marginBottom:40}}>
                        {file_message}
                    </div>}

                    <div className="passenger-form__btns">
                        <a onClick={this.handleClose} className="button button-white">{tMsg('Отмена')}</a>
                        <button onClick={this.handleSubmit} className="button button-base">
                            {ufpid === null ? tMsg('Добавить пассажира') : tMsg('Сохранить пассажира')}
                        </button>
                    </div>
                </div>
            </form>
            <Loading active={isFetching}/>
        </div>
    }
}

const domContainer = document.querySelector('#reactEditPassengerModal');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(EditPassengerModal));
